/* You can add global styles to this file, and also import other style files */

@use "@angular/material" as mat;

@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@400;600&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap');

html,
body {
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;
}

@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

$primaryColor: #7516e9;
$sunglow: $primaryColor;

$secondaryColor: white;
$federalBlue: $secondaryColor;

$pictionBlue: #1fb0ff;
$vividSkyBlue: #39e1ff;

$lime: #DCE500;
$darkMalva: #A96FE7;
$lightMalva: #BBC7FF;

.btn-hg-primary {
  background-color: $darkMalva;
  color: white;
  border-radius: 25px;
  box-shadow: rgb(0 0 0 / 12%) 0px 0.796841px 0.796841px -1.0625px,
    rgb(0 0 0 / 11%) 0px 2.41368px 2.41368px -2.125px,
    rgb(0 0 0 / 9%) 0px 6.38028px 6.38028px -3.1875px,
    rgb(0 0 0 / 2%) 0px 20px 20px -4.25px, rgb(0 0 0 / 25%) 0px 2px 4px 0px;
}

.btn-hg-primary:hover,
.selected {
  background-color: $federalBlue;
  color: black;
}

.btn-hg-white {
  background-color: white;
  color: #202020;
  box-shadow: rgb(0 0 0 / 12%) 0px 0.796841px 0.796841px -1.0625px,
    rgb(0 0 0 / 11%) 0px 2.41368px 2.41368px -2.125px,
    rgb(0 0 0 / 9%) 0px 6.38028px 6.38028px -3.1875px,
    rgb(0 0 0 / 2%) 0px 20px 20px -4.25px, rgb(0 0 0 / 25%) 0px 2px 4px 0px;
}

.btn-hg-white:hover,
.selected {
  background-color: $primaryColor;
  color: white;
}

.form-control:focus {
  border-color: $pictionBlue;
  box-shadow: 0 0 0 0.25rem $vividSkyBlue;
}

.errorMessage {
  color: red;
}

// override datepicker Styles
.datepicker-wrapper {
  background: #f7f9fc;
  padding-bottom: 16px;
  position: relative;

  &.isDisabled {
    opacity: 0.25;
  }

  &.isLoading ngb-datepicker {
    opacity: 0.25;
  }

  .datepicker-loading-wrapper {
    pointer-events: none;
    position: absolute;
    top: calc(50% - 9px);
    left: calc(50% - 9px);
  }

  ngb-datepicker {
    transition: opacity 0.3s ease;
    width: 100%;
    border: none;
  }

  .ngb-dp-header {
    padding: 16px;

    select[aria-label="Select month"],
    select[aria-label="Select year"] {
      border: none;
      background: #f7f9fc;
      direction: rtl;
      padding: 0 2px;
      color: #1a202c;
      font-size: 21px;
      font-weight: 600;
      text-transform: capitalize;
    }

    select[aria-label="Select year"] {
      direction: ltr;
    }

    .ngb-dp-navigation-chevron {
      color: #1a202c;
    }

    .ngb-dp-arrow-btn:disabled {
      opacity: 0;
    }
  }

  .ngb-dp-month {
    width: 100%;
  }

  .ngb-dp-week {
    padding: 0 !important;
    margin: 0;
    border: none;
    background: #f7f9fc;

    .ngb-dp-weekday {
      color: #2d3648;
      font-size: 12px;
      text-transform: lowercase;
      font-style: normal;
    }
  }

  .ngb-dp-day,
  .ngb-dp-weekday,
  .ngb-dp-week-number {
    width: 14.285%;
    text-align: center;
    height: 40px;
    width: 14.285%;
    text-align: center;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      margin: 0;
      padding: 0;
      width: auto;
      height: auto;
    }
  }

  .ngb-dp-day {
    .btn-light:hover {
      outline: none;
    }

    &.ngb-dp-today:not(.disabled) {
      > div:not(.bg-primary) {
        background: white !important;
        padding: 1px;
        border-radius: 100%;
        height: 35px;
        width: 35px;
        opacity: 1;
        color: black !important;
      }
    }

    .bg-primary.text-white {
      background: $darkMalva !important;
      padding: 1px;
      border-radius: 100%;
      height: 35px;
      width: 35px;
    }
  }

  .ngb-dp-day:not(.disabled) .outside {
    color: black !important;
    opacity: 1 !important;
  }
}

// override phone-input styles
ngx-intl-tel-input {
  padding: 0 !important;
  display: block;

  > div {
    width: 100% !important;
  }

  .dropdown-toggle {
    padding-left: 16px;
  }

  ::placeholder {
    color: #cbd2e0;
    opacity: 1;
  }

  #input-id {
    padding: 14px 16px 14px 60px !important;
    border: 1px solid #cbd2e0 !important;
    border-radius: 12px;
    width: 100%;
    outline: none !important;

    &:focus {
      border-color: $darkMalva !important;
      outline: none !important;
      box-shadow: none;
    }
  }

  #country-search-box {
    background-image: url(./../src/assets/formSearch.svg);
    background-position: left calc(0.375em + 0.1875rem) center;
    background-size: 16px;
    background-repeat: no-repeat;
    padding: 10px 10px 10px 30px !important;

    &::placeholder {
      opacity: 0;
    }
  }

  &.ng-invalid {
    #input-id.ng-touched {
      background-image: url(./../src/assets/formError.svg);
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: 24px;
      background-repeat: no-repeat;
      border-color: #e81e61 !important;
    }
  }

  &.ng-valid {
    #input-id.ng-touched {
      background-image: url(./../src/assets/formCheck.svg);
      background-position: right calc(0.375em + 0.1875rem) center;
      background-repeat: no-repeat;
      border-color: #1a202c !important;
    }
  }
}

/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

.ngb-dp-header, .form-select, .datepicker-wrapper .ngb-dp-week .ngb-dp-weekday, .ngb-dp-week, .datepicker-wrapper{
  background-color: #F6F1FD !important;
  text-transform: capitalize;
}

.datepicker-wrapper .ngb-dp-day .btn-light:hover{
  background-color: #E6D4F8  !important;
  text-align: center  !important;
  width: 2rem  !important;
  height: 2rem  !important;
  line-height: 2rem  !important;
  border-radius: 8.25rem  !important;
}